import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="flash"
export default class extends Controller {
  connect() {
    this.display_flashes();
  }

  display_flashes() {
    var flashes = $('.flash-message');
    flashes.each(function(index, el) {
        var type = $(el).attr('class').replace('flash-message ', '');
        $.notify({
          message: $(el).text()
        }, {
          type: type
        });
    });
  }
}
