import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "recipientName",
    "salutation",
    "openingLine",
    "emailBody",
    "closingLine",
    "senderFunctionField",
    "selectFunctionBtn",
    "emailSignature",
    "showSendEmailContainer",
    "sendEmailContainer",
    "repairEmailsContainer",
    "customerServiceEmailsContainer",
    "toggleCustomerServiceMailsButton",
    "toggleRepairServiceMailsButton"
  ];

  connect() {
    this.setInitialSignature();
    $(this.emailBodyTarget).summernote({
      height: 300,
      toolbar: [
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['font', ['strikethrough', 'superscript', 'subscript']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['insert', ['link']],
        ['view', ['codeview']],
      ]
    });
  }

  setSalutation() {
    const name = this.recipientNameTarget.value || "[Voer naam in]";
    const salutation = `Beste ${name},\n\n`;
    this.salutationTarget.value = salutation;
  }

  setOpeningLine(event) {
    this.setSalutation();
    const line = event.target.innerText
    this.openingLineTarget.value = line + "\n\n";
  }

  setEmailBody(event) {
    this.setSalutation();
    let body = event.target.dataset.body;
    body = body.replace(/\\n/g, '\n').replace(/\n/g, '<br>');
    this.emailBodyTarget.value = body + "\n\n";
    this.updateSummernote(this.emailBodyTarget.value);
  }

  appendEmailBody(event) {
    this.setSalutation();
    let body = event.target.dataset.body;
    body = body.replace(/\\n/g, '\n').replace(/\n/g, '<br>');
    this.emailBodyTarget.value += body + "\n\n";
    this.updateSummernote(this.emailBodyTarget.value);
  }

  updateSummernote(content) {
    content = content.replace(/\n/g, "<br>");
    $(this.emailBodyTarget).summernote('code', content);
  }

  setClosingLine(event) {
    this.setSalutation();
    const line = event.target.innerText;
    this.closingLineTarget.value = line + "\n\n";
  }

  setSenderFunction(event) {
    event.preventDefault(); 
    const functionName = event.target.innerText;
    this.senderFunctionFieldTarget.value = functionName;
    this.selectFunctionBtnTarget.innerHTML = `${functionName} <i class="la la-caret-down"></i>`;
    const currentUserFullName = document.getElementById("current_user_full_name").value;
    const signature = this.getSignature(currentUserFullName, functionName);
    this.setSignature(signature);
  }

  toggleCustomerServiceMails(event) {
    event.preventDefault();
    this.repairEmailsContainerTarget.style.display = "none";
    this.customerServiceEmailsContainerTarget.style.display = "block";
    this.toggleCustomerServiceMailsButtonTarget.classList.add("btn-primary");
    this.toggleRepairServiceMailsButtonTarget.classList.remove("btn-primary");

  }

  toggleRepairServiceMails(event) {
    event.preventDefault();
    this.customerServiceEmailsContainerTarget.style.display = "none";
    this.repairEmailsContainerTarget.style.display = "block";
    this.toggleRepairServiceMailsButtonTarget.classList.add("btn-primary");
    this.toggleCustomerServiceMailsButtonTarget.classList.remove("btn-primary");
  }

  toggleEmailButtons(event) {
    const topic = event.target.dataset.topic;
    const emailButtons = document.querySelectorAll(`.email-buttons[data-topic='${topic}']`);
    emailButtons.forEach(button => {
      button.classList.toggle("hidden");
    });
  }

  filterEmails(event) {
    const value = event.target.value.toLowerCase();
    document.querySelectorAll(".email-buttons").forEach(button => {
      button.classList.remove("hidden");
    });
    document.querySelectorAll(".email-button, .email-button-append").forEach(button => {
      button.classList.toggle("hidden", (!button.dataset.title.toLowerCase().includes(value) && !button.dataset.body.toLowerCase().includes(value)));
    });
    if (value === "") {
      document.querySelectorAll(".email-buttons").forEach(button => {
        button.classList.add("hidden");
      });
    }
  }

  clearSearch() {
    document.getElementById("search-bar").value = "";
    this.filterEmails({ target: { value: "" } });
  }

  cancelSendEmail(event) {
    event.preventDefault();  
    document.getElementById('send_email_container').style.display = "none";
    document.getElementById('show-send-email-container').style.display = "block";
  }

  closeEmailContainer() {
    document.getElementById('send_email_container').style.display = "none";
    document.getElementById('show-send-email-container').style.display = "block";
  }

  setInitialSignature() {
    const defaultFunction = document.getElementById("default_function").value;
    const currentUserFullName = document.getElementById("current_user_full_name").value;
    const signature = this.getSignature(currentUserFullName, defaultFunction);
    this.setSignature(signature);
  }

  setSignature(signature) {
    this.emailSignatureTarget.value = signature;
  }

  getSignature(fullName, functionName) {
    const signatureTemplate = document.getElementById("signature_template").value;
    return signatureTemplate.replace("{full_name}", fullName).replace("{function_name}", functionName);
  }
}