import { Controller } from "@hotwired/stimulus"
import debounce from "debounce";

// Connects to data-controller="datatable"
export default class extends Controller {
  static targets = ["select2api"]
  initialize() {
    this.submit = debounce(this.submit.bind(this), 300);
    if (this.hasSelect2apiTarget) {
      this.select2api(this.select2apiTarget) ;
    }
    
  }
  submit() {
    app.datatable.loading(this.scope.element.getAttribute('data-target'));
    this.element.requestSubmit();
  }

  select2api(element) {
    $(element).select2({
      placeholder: element.dataset.placeholder,
      allowClear: true,
      width: '100%',
      ajax: {
        url: element.dataset.url,
        dataType: 'json',
        delay: 250,
      }
    });
  }
}
