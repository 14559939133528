import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="history"
export default class extends Controller {
  static targets = ["form", "search", "map", "controls", "playbackPosition", "positionsCount", "alert"]
  map = null
  locations = []
  currentPositionIndex = 0
  positions = []
  displayRouter = null

  connect() {
    this.renderMap();
  }

  search(event) { 
    event.preventDefault()
    this.removeAllMarkers()
    this.removeAllCircles()
    this.closeAllInfoWindows()
    const url = this.formTarget.action + "?" + new URLSearchParams(new FormData(this.formTarget)).toString()
    history.pushState(null, "", url.replace(".json", ""))
    fetch(url, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      }
    })
      .then(response => response.json())
      .then(json => {
        console.log(json)
        this.setLocations(json)
        
        this.renderPosition(this.currentPositionIndex)
      })
  }

  backward(event) {
    event.preventDefault()
    if (this.currentPositionIndex > 0) {
      this.currentPositionIndex--
      this.renderPosition(this.currentPositionIndex)
      this.playbackPositionTarget.innerHTML = this.currentPositionIndex + 1
    }
  }

  forward(event) {
    event.preventDefault()
    if (this.currentPositionIndex < this.locations.length - 1) {
      this.currentPositionIndex++
      this.renderPosition(this.currentPositionIndex)
      this.playbackPositionTarget.innerHTML = this.currentPositionIndex + 1
    }
  }

  setLocations(locations) {
    this.locations = locations
    this.setPositions(this.locations)

    if (this.locations.length > 0) {
      this.currentPositionIndex = 0
      this.renderControls()
      this.alertTarget.setAttribute("style", "display: none;")
    } else {
      this.alertTarget.removeAttribute("style")
      this.hideControls()
    }

    return this.locations
  }

  setPositions(locations) {
    if (locations.length == 0) {
      this.positions = []
      return
    }
    
    this.positions = locations.map(location => {
      var meta = location.meta_data != null ? JSON.parse(location.meta_data) : {}
      var time = new Date(location.last_location_update)

      return {
        latlng: new google.maps.LatLng(location.lat, location.lng),
        type: location.type.toLocaleLowerCase(),
        time: time.toLocaleString(),
        meta: meta,
        signal: location.signal_strength,
        accuracy: location.satelite_count,
        battery: location.battery_level,
        info_window: this.infoWindowFor(location),
        polylines: [],
      }
    })

    return this.positions
  }

  infoWindowFor(location) {
    var meta = location.meta_data != null ? JSON.parse(location.meta_data) : {}
    var accuracy = meta.accuracy_meters || "0"
    accuracy = parseFloat(accuracy).toFixed(2)
    var time = new Date(location.last_location_update).toLocaleString()
    return `
      <div class="infowindow-content">
        <div class="header">
          <div class="row">
            <div class="col-12">
              <i class="fa fa-clock-o"></i> ${time}
            </div>
          </div>
        </div>

        <div class="container">
          <div class="row">
            <div class="col-12">
              <p>
                <i class="fa fa-crosshairs"></i> ${accuracy}m &nbsp; &nbsp;
                <i class="fa fa-signal"></i> ${location.signal_strength} &nbsp; &nbsp; 
                <i class="fa fa-globe"></i> ${location.satelite_count} &nbsp; &nbsp;
                <i class="fa fa-battery-${Math.ceil(location.battery_percentage / 20) - 1}"></i> ${location.battery_percentage}
              </p>
              ${this.infowindowRouters(location)}
            </div>
          </div>
        </div>
      </div>
    `
  }

  panTo(event) {
    var mac_address = event.params.macAddress
    console.log(event)
    this.locations[this.currentPositionIndex].routers.forEach(router => {
      if (router.mac_address == mac_address) {
        var lats = router.locations.map(location => location.lat)
        var lngs = router.locations.map(location => location.lng)
        var lat = lats.reduce((a, b) => a + b, 0) / lats.length
        var lng = lngs.reduce((a, b) => a + b, 0) / lngs.length
        this.map.panTo(new google.maps.LatLng(lat, lng))
      }
    });
  }

  filterRouter(event) {
    var mac_adres = event.params.macAddress
    this.displayRouter = mac_adres;
    this.renderRouters(this.currentPositionIndex)
  }

  unfilterRouter(event) {
    this.displayRouter = null
    this.renderRouters(this.currentPositionIndex)
  }

  infowindowRouters(location) {
    return location.routers.map(router => {
      var average_accuracy = (router.locations.map(location => parseFloat(location.accuracy)).reduce((a, b) => a + b, 0) / router.locations.length).toFixed(2)
      return `
        <div class="row">
          <div class="col-12" style="cursor: pointer" data-action="click->history#panTo mouseenter->history#filterRouter mouseout->history#unfilterRouter" data-history-mac_address-param='${router.mac_address}'">
            <span style="color: ${router.color}"> 
              <i class="fa fa-wifi"></i> ${router.mac_address} (${router.name})&nbsp; &nbsp;
              <i class="fa fa-map-marker"></i>
              - ${router.locations.length}
              - ${average_accuracy}m
            </span>
          </div>
        </div>
      `
    }).join("") || "";
  }

  renderMap() {
    if (this.map == null) {
      var latlng = new google.maps.LatLng(52.1326, 5.2913);
      this.map = new google.maps.Map(this.mapTarget, 
        {
          center: latlng,
          zoom: 15,
          mapId: 'DEMO_MAP_ID'
        }
      )
    } else {
      console.log("Map already rendered")
    }
  }

  renderPolyLine(index) {
    if (this.positions.length == 0 || index < 1) {
      return
    }

    const position = this.positions[index]
    if (position.polyline != null) {
      position.polyline.setMap(null)
    }

    var start_path = this.positions[index - 1].latlng
    var end_path = position.latlng
    var polyline = new google.maps.Polyline({
      path: [start_path, end_path],
      geodesic: true,
      strokeColor: app.device_history.data_type_to_color(position.type.toLowerCase()),
      strokeOpacity: 0.7,
      strokeWeight: 2
    });

    position.polylines.push(polyline)
    polyline.setMap(this.map)
  }

  renderPosition(index) {
    if (this.positions.length == 0) {
      return
    }

    const position = this.positions[index]
    this.map.setCenter(position.latlng)
    this.map.setZoom(18)

    if (position.marker != null) {
      position.marker.setMap(null)
    }
    position.marker = new google.maps.Marker({
      position: position.latlng,
      map: this.map,
      title: position.time,
      icon: app.icons[position.type],
      zIndex: index
    });

    this.closeAllInfoWindows()
    var infowindow = new google.maps.InfoWindow({
      content: position.info_window
    });

    position.infowindow = infowindow
    infowindow.open(this.map, position.marker);
    this.renderRouters(index);
    this.renderPolyLine(index);
  }

  renderRouters(index) {
    if (this.positions.length == 0) {
      return
    }
    const position = this.positions[index]
    
    this.removeAllCircles();

    position.router_location_circles = [];
    
    this.locations[index].routers.map(router => {
      if (this.displayRouter != null && this.displayRouter != router.mac_address) {
        return
      }
      return router.locations.map(location => {
        var circle = new google.maps.Circle({
          strokeColor: router.color,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: router.color,
          fillOpacity: 0.15,
          title: router.mac_address,
          map: this.map,
          center: new google.maps.LatLng(location.lat, location.lng),
          radius: parseFloat(location.accuracy)
        });

        var marker = new google.maps.Marker({
          position: new google.maps.LatLng(location.lat, location.lng),
          map: this.map,
          title: router.mac_address + " - " + location.accuracy + "m",
          icon: app.icons["none"],
          zIndex: index
        });
        position.router_location_circles.push({shape: circle, marker: marker});
      });
    });
  }

  closeAllInfoWindows() {
    this.positions.forEach(position => {
      if (position.infowindow != null)
        position.infowindow.close()
    })
  }

  removeAllCircles() {
    this.positions.forEach(position => {
      if (position.router_location_circles != null) {
        position.router_location_circles.forEach(circle => {
          circle.shape.setMap(null);
          circle.marker.setMap(null)
        })
      }
    })
  }

  removeAllMarkers() {
    this.positions.forEach(position => {
      if (position.marker != null)
        position.marker.setMap(null)
    })
  }

  renderControls() {
    this.controlsTarget.removeAttribute("style")
    this.playbackPositionTarget.innerHTML = this.currentPositionIndex + 1;
    this.positionsCountTarget.innerHTML = this.locations.length;
  }

  hideControls() {
    this.controlsTarget.setAttribute("style", "display: none;")
  }
}
