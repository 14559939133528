import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="fences-form"
export default class extends Controller {
  static targets = ["fenceType", "alarmType", "lat", "lng", "radius", "fencePoints", "form", "container"]
  static values = {
    fence: Object,
  }

  connect() {
    this.dispatch('edit', { detail: { editMode: true, fence: this.fenceValue } });
    if (this.fenceValue.id == null) {
      this.dispatch('render', { detail: { fences: undefined } });
    }
    document.addEventListener('turbo:submit-end', (e) => {
      if (e.detail.fetchResponse.response.status == 200) {
        this.dispatch('reload', { detail: { fences: undefined } });
      } else {
        this.dispatch('edit', { detail: { editMode: true, fence: this.fenceValue } });
      }
    });
  }

  updateForm({ detail: data}) {
    this.fenceValue = data.fence;
    this.latTarget.value = this.fenceValue.latitude;
    this.lngTarget.value = this.fenceValue.longitude;
    this.radiusTarget.value = this.fenceValue.radius;
    if (this.fenceValue.fence_points != null) {
      this.fencePointsTarget.value = this.fenceValue.fence_points.map(function(point) {
        return point.lat + ',' + point.lng;
      }).join(';');
    } else {
      this.fencePointsTarget.value = null;
    }
  }

  cancel() {
    this.dispatch('edit', { detail: { editMode: false, fence: undefined } });
    this.dispatch('render', { detail: { fences: undefined } });
    this.containerTarget.remove();
  }

  setName(event) {
    this.fenceValue = { ...this.fenceValue, name: event.target.value };
  }

  setFenceType(event) {
    this.fenceValue = { ...this.fenceValue, fence_type: event.target.value };
    this.dispatch('edit', { detail: { editMode: true, fence: this.fenceValue } });
  }

  setAlarmType(event) {
    this.fenceValue = { ...this.fenceValue, alarm_type: event.target.value };
    this.dispatch('edit', { detail: { editMode: true, fence: this.fenceValue } });
  }

  // probably unnecessary, but just in case
  setLat(latitude) {
    this.fenceValue = { ...this.fenceValue, latitude: latitude };
    this.latTarget.value = latitude;
  }

  setLng(longitude) {
    this.fenceValue = { ...this.fenceValue, longitude: longitude };
    this.lngTarget.value = longitude;
  }

  setRadius(radius) {
    this.fenceValue = { ...this.fenceValue, radius: radius };
    this.radiusTarget.value = radius; 
  }

  setFencePoints(points) {
    this.fenceValue = { ...this.fenceValue, fence_points: points };
    this.fencePointsTarget.value = points;
  }

  disconnect() {
    this.dispatch('edit', { detail: { editMode: false, fence: undefined } });
    this.dispatch('render', { detail: { fences: undefined } });  
  }
}
