import { Controller } from "@hotwired/stimulus"
import debounce from "debounce";

// Connects to data-controller="global-search"
export default class extends Controller {
  lastKeyUpTime = 0;
  visible = false;
  connect() {
  }

  initialize() {
    if (window.globalSearchInitialized == undefined) {
      this.listenForKeys();
      this.submit = debounce(this.submit.bind(this), 500);

      window.globalSearchInitialized = true;
    }
  }

  submit() {
    var table = $('.global-search .m-datatable');
    table.addClass('loading');
    table.append('<div class="anim-container"><div class="anim"><span></span> <span></span> <span></span></div></div>');
    $('.global-search form')[0].requestSubmit();
  }

  close() {
    $('.global-search').hide();
    this.visible = false;
  }

  show() {
    $('.global-search').show();
    $('.global-search input').focus();
    $('.global-search input').select();

    this.visible = true;
  } 

  selectPrevious() {
    var selectedIndex = $('.global-search .m-datatable tbody tr.selected').index()
    if (selectedIndex > 0) {
      $('.global-search .m-datatable tbody tr.selected').removeClass('selected');
      $('.global-search .m-datatable tbody tr').eq(selectedIndex - 1).addClass('selected');
    } else {
      $('.global-search .m-datatable tbody tr.selected').removeClass('selected');
      $('.global-search .m-datatable tbody tr').last().addClass('selected');
    }
  }

  selectNext() {
    var selectedIndex = $('.global-search .m-datatable tbody tr.selected').index()
    if (selectedIndex < $('.global-search .m-datatable tbody tr').length - 1) {
      $('.global-search .m-datatable tbody tr.selected').removeClass('selected');
      $('.global-search .m-datatable tbody tr').eq(selectedIndex + 1).addClass('selected');
    } else {
      $('.global-search .m-datatable tbody tr.selected').removeClass('selected');
      $('.global-search .m-datatable tbody tr').first().addClass('selected');
    }
  }

  visitSelected() {
    // window.globalSearchInitialized = undefined;
    $('.global-search .m-datatable tbody tr.selected a')[0].click();
    this.visible = false;
  }

  listenForKeys() {
    document.removeEventListener("keydown", this.keyDown.bind(this));
    document.removeEventListener("keyup", this.keyUp.bind(this));
    document.addEventListener("keydown", this.keyDown.bind(this));
    document.addEventListener("keyup", this.keyUp.bind(this));
  }

  keyDown(event) {
    if (this.visible) {
      if (event.key === "ArrowDown") {
        this.selectNext();
      }

      if (event.key === "ArrowUp") {
        this.selectPrevious();
      }

      if (event.key === "Enter") {
        event.preventDefault();
        this.visitSelected();
      }
    } else {
      if (event.key === "f" && event.ctrlKey) {
        event.preventDefault();
        this.show();
      }
    }
  }

  keyUp(event) {
    if (this.visible) {
      event.preventDefault();
      if (event.key === "Escape") {
        this.close();
      }
      
      
    }
  }
}
