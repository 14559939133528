import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="image-field"
export default class extends Controller {
  connect() {
  }

  // Called when the image field changes
  change(event) {
    const input = event.target
    if (input.files && input.files[0]) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const parent = $(input).parent().parent()
        parent.find(".image-input-wrapper img").attr('src', e.target.result)
        parent.find('.remove_btn').show();
        parent.find('input[type="checkbox"]').prop('checked', false);
      }
      reader.readAsDataURL(input.files[0])
    }
  }

  // Called when the image field is clicked
  click(event) {
    const input = event.target;
    const parent = $(input).parent().parent();
    parent.find('input[type="file"]').click()
  }

  // Called when the remove image field is clicked
  remove(event) {
    const input = event.target;
    const parent = $(input).parent().parent();
    const default_src = parent.find(".default-img").attr('src');
    parent.find('input[type="checkbox"]').prop('checked', true);
    parent.find(".image-input-wrapper img").attr('src', default_src);
    parent.find('.remove_btn').hide();
  }
}
