import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="limited-text-field"
export default class extends Controller {
  static values = {
    limit: Number,
  }

  static targets = ["input"];
  connect() {
    this.inputTarget.addEventListener("input", this.update.bind(this));
    

    this.setLimitView();

    this.update();
  }

  update() {
    var current_count = this.inputTarget.value.length;
    var limit = this.limitValue;
    var limit_element = this.inputTarget.parentElement.querySelector(".input-limit");
    if (current_count >= limit) {
      limit_element.classList.add("limit-reached");
      this.inputTarget.value = this.inputTarget.value.substring(0, limit);
      current_count = this.inputTarget.value.length;
    } else {
      limit_element.classList.remove("limit-reached");
    }
    limit_element.querySelector(".current").innerHTML = current_count;
  }

  setLimitView() {
    var current_count = this.inputTarget.value.length;
    $(this.inputTarget).after(`
      <div class="input-limit">
        <span class="current">${current_count}</span>
        <span class="separator">/</span>
        <span class="limit">${this.limitValue}</span>
      </div>
    `)
  }
}
