import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["emailTopic", "summernote", "form", "selectElement"];

  connect() {
    // Initialize Summernote
    $(this.summernoteTarget).summernote({
      height: 300,  
      toolbar: [
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['font', ['strikethrough', 'superscript', 'subscript']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['insert', ['link']],
        ['view', ['codeview']],
      ],
      styleTags: [
        'p',
        {
          tag: 'p',
          title: 'No Spacing',
          className: 'MsoNoSpacing'
        }
      ],
      codeviewFilter: false, 
      codeviewFilterRegex: ''
    });

    // Bind form submit to update the content from Summernote
    this.formTarget.addEventListener('submit', (event) => {
      var summernoteContent = $(this.summernoteTarget).summernote('code');
      this.summernoteTarget.value = summernoteContent;
    });

    // Handle dropdown topic selection
    this.selectElementTarget.addEventListener('change', () => {
      this.emailTopicTarget.value = this.selectElementTarget.value;
    });
  }
}