import { Controller } from "@hotwired/stimulus"
import debounce from "debounce";

// Connects to data-controller="debounced-validate"
export default class extends Controller {
  static targets = ["validateBtn", "input"];

  initialize() {
    this.submit = debounce(this.submit.bind(this), 500);
    this.validateBtnTarget.hidden = true;
  }

  startSubmit() {
    parent = this.inputTarget.parentElement
    if (parent.classList.contains('field_with_errors')) {
      parent = parent.parentElement;
    }
    parent.classList.add('is-validating');
    this.submit();
  }

  submit() {
    parent = this.inputTarget.parentElement
    if (parent.classList.contains('field_with_errors')) {
      parent = parent.parentElement;
    }
    parent.classList.add('is-validating');
    this.validateBtnTarget.click();
  }
}
