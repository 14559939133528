import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="update-check"
export default class extends Controller {
  static targets = [ "check", "form"]
  static values = { formState: String }
  connect() {
    console.log(this.checkTarget) 
    this.formStateValue = $(this.formTarget).serialize();
    $(this.checkTarget).find('.form-group').addClass('disabled');
  }

  check() {
    let new_state = $(this.formTarget).serialize();
    if (new_state == this.formStateValue) {
      $(this.checkTarget).find('input').prop('checked', false);
      $(this.checkTarget).find('.form-group').addClass('disabled');
      
    } else {
      $(this.checkTarget).find('.form-group').removeClass('disabled');
    }
  }
}
