import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="remote-pagination"
export default class extends Controller {
  initialize() {
    this.keyUp = this.keyUp.bind(this)
  }
  connect() {
    document.addEventListener("keyup", this.keyUp)
  }

  disconnect() {
    document.removeEventListener("keyup", this.keyUp)
  }

  keyUp(event) {
    if (event.key == "ArrowRight") {
      this.next()
    } else if (event.key == "ArrowLeft") {
      this.previous()
    }
  } 

  next() {
    let next = this.element.querySelector("a[rel='next']")
    if (next) {
      next.click()
      app.datatable.loading(this.scope.element);
    }
  }

  previous() {
    let previous = this.element.querySelector("a[rel='prev']")
    if (previous) {
      previous.click()
      app.datatable.loading(this.scope.element);
    }
  }
}
