import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="command-form"
export default class extends Controller {
  static targets = ["homeFxs", "commandsField", "commands", "defaultCommands", "commandSort"];
  static values = {
    commands: {type: Array, default: []}
  }

  connect() {
    this.setCommands();
    console.log("command form connected");

    this.initHomeFxListeners();
    this.setCommandListeners();
    this.populateSortablesList();
    var self = this;

    $(this.commandSortTarget).sortable({
      update: function(event, ui) {
        self.updateDefaultCommands();
        self.sortCheckedFirst();
        setTimeout(function() {
          
        }, 100);
        
      }
    });
  }

  setCommands() {
    var commands = [];
    var self = this;
    $(this.homeFxsTarget).find('input').each(function(index, element) {
      if ($(element).prop('checked')) {
        var id = self.getId(element);
        commands.push(id);
      }
    });

    $(this.commandsTarget).find('input').each(function(index, element) {
      if ($(element).prop('checked')) {
        var id = self.getId(element);
        commands.push(id);
      }
    });
    this.commandsValue = commands;
    $(this.commandsFieldTarget).val(this.commandsValue.join(','));
  }

  addToCommands(element) {
    var id = this.getId(element);
    var commands = this.commandsValue;
    commands.push(id);
    this.commandsValue = commands;
    $(this.commandsFieldTarget).val(this.commandsValue.join(','));
    this.addToSortablesList(id);
  }

  removeFromCommands(element) {
    var id = this.getId(element);
    var commands = this.commandsValue;
    commands = this.commandsValue.filter(function(value, index, arr) {
      return value != id;
    });
    this.commandsValue = commands;
    $(this.commandsFieldTarget).val(this.commandsValue.join(','));
    this.removeFromSortablesList(id);
  }

  initHomeFxListeners() {
    var self = this;
    $(this.homeFxsTarget).find('input').each(function(index, element) {
      $(element).change(function() {
        var checked = $(element).prop('checked');
        if (checked) {
          self.addToCommands(element);
        } else {
          self.removeFromCommands(element);
        }
      });
    });
  }


  setCommandListeners() {
    var self = this;
    $(this.commandsTarget).find('input').each(function(index, element) {
      $(element).change(function() {
        var checked = $(element).prop('checked');
        if (checked) {
          self.addToCommands(element);
        } else {
          self.removeFromCommands(element);
        }
      });
    });
  }

  populateSortablesList() {
    this.clearSortablesList();
    var self = this;
    
    var defaultCommands = $(this.defaultCommandsTarget).val().split(',');

    var commands = $(this.commandsFieldTarget).val().split(',').filter(function(el) {
      return defaultCommands.indexOf(el) < 0;
    })
    console.log(commands)
    console.log(defaultCommands)
    console.log(defaultCommands.length)
    for (var i = 0; i < defaultCommands.length; i++) {
      var command = defaultCommands[i];
      if (command == "") {
        continue;
      }
      var el = $(self.homeFxsTarget).find(`input[id*=${command}_]`);
      if (el.length == 0) {
        el = $(self.commandsTarget).find(`input[id*=${command}_]`);
      }

      this.createSortableElement(el, true);
    }

    for(var i = 0; i < commands.length; i++) {
      var command = commands[i];
      var el = $(self.homeFxsTarget).find(`input[id*=${command}_]`);
      if (el.length == 0) {
        el = $(self.commandsTarget).find(`input[id*=${command}_]`);
      }

      this.createSortableElement(el, false);
    }
  }

  addToSortablesList(id) {
    var el = $(this.homeFxsTarget).find(`input[id*=${id}_]`);
    if (el.length == 0) {
      el = $(this.commandsTarget).find(`input[id*=${id}_]`);
    }
    this.createSortableElement(el, false);
  }

  removeFromSortablesList(id) {
    var el = $(this.commandSortTarget).find(`input[id*=${id}_]`).closest('.sortable_function');
    el.off('click');
    el.remove();
  }

  createSortableElement(element, checked) {
    if ($(element).length == 0) {
      return;
    }
    var el = $(element).parent().clone();
    el.find('input').prop('checked', checked);
    var li = $('<li class="sortable_function"></li>').append(el);
    var self = this;
    li.on('click', function() {
      console.log("que")
      self.updateDefaultCommands();
      setTimeout(function() {
        self.sortCheckedFirst();
      }, 100);
    });
    $(this.commandSortTarget).append(li);
    return el;
  }

  clearSortablesList() {
    $(this.commandSortTarget).find('.sortable_function').off('click').remove();
  }

  updateDefaultCommands() {
    var commands = [];
    var self = this;
    $(this.commandSortTarget).find('input').each(function(index, element) {
      if (!$(element).prop('checked')) {
        return;
      }
      var id = self.getId(element);
      commands.push(id);
    });
    $(this.defaultCommandsTarget).val(commands.join(','));
  }

  sortCheckedFirst() {
    this.populateSortablesList();
  }

  getId(element) {
    return $(element).attr('id')
      .replace('command', '')
      .replace('homefx', '')
      .replace('limited', '')
      .replace('standard', '')
      .replace("_", '')
      .replace("_", '')
      .replace("_", '');
  }
}
