import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="account-validator"
export default class extends Controller {
  static targets = ["submitbtn", "accountSelector"]

  connect() {
    this.submitbtnTarget.hidden = true;
    var self = this;
    $(this.accountSelectorTarget).on("select2:select", function(e) {
      self.remoteSubmit();
    })

    document.addEventListener('turbo:frame-load', (e) => {
      $('.select2').select2();
    });
  }

  initialize() {
    document.addEventListener('turbo:submit-end', (e) => {
      if (e.detail?.formSubmission?.fetchRequest?.body?.get('_method') === 'delete') {
        return;
      }

      if (e.detail.success && e.detail.fetchResponse.response.status == 303) {
        return;
      }
      
      if (e.detail.success && e.detail.formSubmission.submitter.formMethod != 'get') {
        if (e.detail.fetchResponse.response.url != undefined) {
          if (e.detail.fetchResponse.response.url == window.location.href) {
            window.location.reload();
          } else {
            Turbo.visit(e.detail.fetchResponse.response.url, { action: 'replace' })
          }
        }
      }
    });
  }

  remoteSubmit() {
    this.submitbtnTarget.click();
  }
}
