import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="repairmodal"
export default class extends Controller {

  connect() {
    console.log("RepairModalController connected");
    $('#repair_modal').modal({
      backdrop: 'static',
    });
  }

}
