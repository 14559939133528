import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

// The HTML code for the spinner.
const spinner = `
<div class='inline-anim-container' id="loader">
  <div class='anim'>
    <span></span> 
    <span></span> 
    <span></span>
  </div>
</div>`;

export default class extends Controller {
  static fetching = false; // debounce

  static values = {
    url: String,
    page: { type: Number, default: 2 },
    reverse: {type: Boolean, default: false},
  };

  static targets = ["container", "noRecords"];

  initialize() {
    // this.containerTarget.scrollTop = this.containerTarget.scrollHeight;
    this.scroll = this.scroll.bind(this);
  }

  connect() {
    this.containerTarget.addEventListener("scroll", this.scroll);
  }

  scroll() {
    if (this.#pageEnd && !this.fetching && !this.hasNoRecordsTarget) {
      // Add the spinner at the end of the page.
      this.containerTarget.insertAdjacentHTML("afterbegin", spinner);

      this.#loadRecords();
    }
  }

  // Send a turbo-stream request to the controller.
  async #loadRecords() {
    const url = new URL(this.urlValue);
    url.searchParams.set("page", this.pageValue);

    this.fetching = true;

    await get(url.toString(), {
      responseKind: "turbo-stream",
    });

    this.fetching = false;
    this.pageValue += 1;
  }

  // Detect if we're at the bottom of the page.
  get #pageEnd() {
    const { scrollTop, scrollHeight, clientHeight } = this.containerTarget;
    if (this.reverseValue) {
      return scrollTop < 100;
    } else {
      return scrollHeight - scrollTop - clientHeight < 40;
    }
  }
}
